/**
 * Vuex-mutation
 */
export default {

  saveUserInfo(state,count) {
    state.userInfo = count;
    localStorage.setItem('userInfo',JSON.stringify(count))
    localStorage.setItem('token',count.id)
  },
  logOut(state){
    state.userInfo = '';
    state.token = ''
    localStorage.clear()
  }
}
