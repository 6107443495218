/**
 * 环境变量
 * @description 提前配置环境变量：package.json>scripts>--mode=development，通常分为：开发、测试、生产
 */

let baseURL = ''
const env = process.env.NODE_ENV // 获取当前node.js进程中环境变量
switch (env) {
  // 开发环境：npm run serve https://www.yocamily.com/ds/api
  case 'development':
    // baseURL = 'https://www.yocamily.com/ds/'
    baseURL = 'https://admin.akomapa.online/'
    break;
  // 测试环境: npm run test
  case 'test':
    // baseURL = 'https://www.yocamily.com/ds/'
    baseURL = 'https://admin.akomapa.online/'
    break;
  // 生产环境：npm run build
  case 'production':
    // baseURL = 'https://www.yocamily.com/ds/'
    baseURL = 'https://admin.akomapa.online/'
    break;
  default:
    // http://140.210.209.245:8080/ZY/
    // baseURL = 'https://www.yocamily.com/ds/'
    baseURL = 'https://admin.akomapa.online/'
    break;
}

export default {
  baseURL
}