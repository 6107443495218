export default {
	menu: {
		fenlei: 'GOODS',
		huodong: 'ACTIVITY',
		shouhao: 'AFTER SALE',
		fulanm: 'SERVE',
		guanyuwomen: 'ABOUT US',
		boke: 'COMMUNITY',
		vipzhidu: 'MEMBERSHIP SYSTEM',
		shareText: 'Search For Products',
		megluyan: 'Leave A Message',
		about: ['Company Profile', 'Brand Introduction', 'Shopping Instructions', 'Customer Message',
			'PrivacyRights/TermsOfUse'
		],
		weixiu: [
			'MaintenanceProcess',
			'MaintenanceChargeRules',
			'MaintenanceInstructions'
		]
	},
	footer: {
		lianxi: {
			title: 'CONNECT WITH US',
			name: 'YOCAMILY',
			tel: 'Phone',
			email: 'Email',
			time: 'Hours'
		},
		about: {
			title: 'ABOUT',
			ppgs: 'Our History',
			gmqd: 'Purchasing Channel',
			ystk: 'Privacy',
			sytk: 'Terms For Usage'
		},
		aftersale: {
			title: 'Serve',
			serve: 'Leave A Message',
			tuikuan: 'Returns & Refunds',
			ppwx: 'Warranty & Repair',
			vip: "VIP Rights",
			question: 'FAQ',
			lianxi: 'Contact US',
			wlcx: 'Logistics inquiry'
		},
		join: 'JOIN OUR MAILING LIST',
		dingyue: 'SUBSCRIBE',
		title: 'Join our email list to receive news about product launches & events, creative gear-use insights, tasty recipes and outdoor inspiration.',
		emailAdrs: 'Email Address',
		jiaru: 'Join Us'
	},
	button: {
		chakan: 'View now',
		gengduo: 'More',
		jiaruche: 'Add to cart',
		qupingjia: 'To evaluate',
		tijiao: 'Submit',
		fukuan: 'To pay',
		lijitijao: 'Immediate submission',
		baoming: 'Join us',
		quxiaodingdan: 'Cancellation of order',
		quzhifu: 'To pay',
		tuikuan: 'Apply for refund',
		shouhuo: 'Confirm receipt',
		shenqingshouhuo: 'After sales',
		chexiao: 'Revoke',
		see: 'View application',
		queding: 'Sure',
		quxiao: 'Cancel',
		chakna: 'See',
		chakanwuliu: 'View logistics',
	},
	message: {
		quxiaotisi: 'Cancel prompt'
	},
	login: {
		login: 'LOGIN',
		zhuce: 'REGISTER',
		wangjips: 'Forgot password',
		inputPhoneandEmier: 'Please enter the email address',
		inputPs: 'Please input a password',
		inputCode: 'Please enter the verification code',
		quzhuce: 'Go to register',
		agreementTitle: 'By owning an account, you agree',
		quanyi: 'Description of Membership Interests',
		tiaokun: 'Website Terms of Use',
		and: 'and',
		code: 'get code'
	},

	details: {
		live: 'Collect',
		sku: 'Inventory',
		yuefei: 'Freight Charge',
		pinglun: 'Comment',
		nuber: 'comments'
	},
	submitOrder: {
		goodsXi: 'Product information',
		goodsPic: 'Commodity amount',
		peisong: 'Distribution fee',
		heji: 'Amount to',
		jieshuan: 'To settle',
		yingfujine: 'Amount payable',
		wahctQce: 'WeChat payment',
		alipeyQce: 'Alipay payment',
		addAdress: 'Add address!'
	},
	mine: {
		menuList: ["PersonalData ", "Favorites", 'ShoppingCart', 'OrderManagement', "TheMemberPointsAndGrade",
			"SiteInformation", "Register/Login"
		],
		menuList1: ["PersonalData ", "Favorites", 'ShoppingCart', 'OrderManagement', "TheMemberPointsAndGrade",
			"SiteInformation", "Logout"
		],
		// menuList2:['PersonalData ','Favorites', 'ShoppingCart', 'OrderManagement', 'MemberPointsAndGrades','SiteInformation',' ChangePassword '],
		menuList2: ['PersonalData ', 'ShoppingCart', 'OrderManagement', 'MemberPointsAndGrades', ' ChangePassword '],
		pepol: {
			touxiang: 'Modify avatar',
			necheng: 'Nickname',
			xingbie: 'Gender',
			plece: 'Please enter content',
			phoen: 'cell-phone number',
			pleasePhoen: 'Please enter your mobile number',
			adr: 'Shipping address',
			button: 'Complete',
			xuanze: 'Please select'
		},
		orderList: ['ToBePaid', 'Consignment', 'ToBeReceived', 'ToBeEvaluated', 'Completed', 'Canceled',
			'Refund/AfterSales'
		],
		vipjifen: {
			myjifen: 'My Points',
			dangqiandengji: 'Current level',
			title: 'Get records',
			tiem: 'Time',
			name: 'Project',
			jifen: 'Integral',
			dengji: 'Grade'
		},
		messge: {
			list: ['System Notification ', 'Arrival Reminder'],
			look: 'Go to see',
			messge: 'Goods have arrived',
			title: 'The goods you bought have arrived. Go and have a look'
		},
		setting: {
			oldPs: "Please enter the original password",
			oldTitle: 'Original password',
			newPs: "Please input a password",
			newTitle: 'New Password',
			Ps: "Please enter the new password again",
			Title: 'Confirm Password',
			xiugmima: 'Change Password'
		},
		elses: {
			cause: 'Please select the after-sales reason',
			jianshu: 'Please briefly explain the specific reasons for after-sales service',
			miaoshu: 'Supplementary description',
			pinjia: 'Fill in the evaluation',
			pinfen: 'Score',
			neirong: 'Content',
			aftersale: 'After-sale goods',
			shenqing: 'View application',

			heji: 'Amount In Total',
			fahuoxiangqing: 'Details to be shipped',
			zdshouhuotime: 'Automatic receipt time',
			tuikuanTime: 'Refund tips',
			tuikuanContent: 'Are you sure you want to apply for a refund ？',
			button: 'Think about it',
			dingdanbiaohao: 'Order number',
			xiadantime: 'Order time',
			zhifutype: 'State of payment',
			pay: 'Paid',
			payTime: 'Pay Time',
			fahuotime: 'Delivery time',
			shouhuotime: 'Receiving time',
			querentis: 'Receiving prompt',
			quxiaodingdan: 'Are you sure you want to cancel the order?',
			querenShouhuo: 'Are you sure to receive the goods?',
			chexiaoshenqing: 'Are you sure you want to cancel the refund/after-sales application？',
			payjine: 'Payment amount',
			quxiaotime: 'Cancellation time'
		}
	}
}