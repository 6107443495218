/**
 * 商城模块接口列表
 */
import axios from '@/api/http.js'; // 导入http中创建的axios实例
// import qs from 'qs'; // 根据需求是否导入qs模块



// const main = {
//     // 认证
//     listCarousel(params){
//         return axios.post(`/api/listCarousel`,  params );
//     },
// }





export const listCarousel = (params) => { return axios.post(`/api/listCarousel`, params) };
//访客登录
export const visitorLogin = (params) => { return axios.post(`/api/visitorLogin`, params) };
export const upload = (params) => { return axios.post(`/common/upload`, params) };
//发送验证码
export const sendVerificationCode = (params) => { return axios.post(`/api/sendVerificationCode`, params) };
//发送邮箱验证码
export const sendEmailCode = (params) => { return axios.post(`/api/sendEmailCode`, params) };
//注册
export const register = (params) => { return axios.post(`/api/register`, params) };
//登陆
export const login = (params) => { return axios.post(`/api/login`, params) };
//更新用户信息
export const updateUser= (params) => {params.id = localStorage.getItem('token'); return axios.post(`/api/updateUser`, params) };
//获取用户详情
export const getUserDetail = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/getUserDetail`, params) };
//修改密码
export const changePassword = (params) => { params.userId = localStorage.getItem('token');  return axios.post(`/api/changePassword`, params) };
//重置密码
export const resetPassword = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/resetPassword`, params) };
//获取消息列表
export const listMessage = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/listMessage`, params) };
//获取用户积分记录
export const listUserIntegralRecord = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/listUserIntegralRecord`, params) };
//获取用户订单列表
export const listUserOrder = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/listUserOrder`, params) };
//查询订单详情
export const getOrderDetails = (params) => {  return axios.post(`/api/getOrderDetails`, params) };
//取消订单
export const cancelOrder = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/cancelOrder`, params) };
//退款售后申请
export const refundAfterOrder = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/refundAfterOrder`, params) };
//确认收货
export const receiveOrder = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/receiveOrder`, params) };
//撤销申请
export const revocationOrder = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/revocationOrder`, params) };
//订单评价
export const evaluateOrder = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/evaluateOrder`, params) };
//加入购物车
export const addShoppingCart = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/addShoppingCart`, params) };
//获取用户购物车列表
export const listUserShoppingCart = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/listUserShoppingCart`, params) };
//修改购物车商品数量
export const updateShoppingCart = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/updateShoppingCart`, params) };
//删除购物车商品
export const deleteShoppingCart = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/deleteShoppingCart`, params) };
//获取商品分类列表
export const listGoodsClassify = (params) => {  return axios.post(`/api/listGoodsClassify`, params) };
//获取商品列表
export const listGoods = (params) => { return axios.post(`/api/listGoods`, params) };
//获取商品详情
export const getGoodsDetail = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/getGoodsDetail`, params) };
//获取商品规格价格
export const getGoodsSpecPrice = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/getGoodsSpecPrice`, params) };
//收藏商品
export const collect = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/collect`, params) };
//获取活动情报
export const getActivityInfo = (params) => { return axios.post(`/api/getActivityInfo`, params) };
//获取获取售后服务信息
export const getAfterSales = (params) => { return axios.post(`/api/getAfterSales`, params) };
//获取服务栏目信息
export const getServiceColumn = (params) => { return axios.post(`/api/getServiceColumn`, params) };
//获取关于我们栏目信息
export const getAboutUs = (params) => { return axios.post(`/api/getAboutUs`, params) };
//获取问题种类列表
export const listFaqType = (params) => { return axios.post(`/api/listFaqType`, params) };
//获取问题详情列表
export const listFaqDetail = (params) => { return axios.post(`/api/listFaqDetail`, params) };
//1公司介绍、2品牌介绍、3购物说明、4隐私权益及网站使用条款、5会员制度
export const getProtocol = (params) => { return axios.post(`/api/getProtocol`, params) };
//客户留言
export const feedback = (params) => { params.userId = localStorage.getItem('token'); return axios.post(`/api/feedback`, params) };
//获取博客列表
export const listBlogs = (params) => { return axios.post(`/api/listBlogs`, params) };
//获取博客详情
export const getBlogsDetail = (params) => { return axios.post(`/api/getBlogsDetail`, params) };
//评论商品
export const comment = (params) => {params.userId = localStorage.getItem('token'); return axios.post(`/api/comment`, params) };
//获取商品评论
export const listGoodsComment= (params) => {params.userId = localStorage.getItem('token'); return axios.post(`/api/listGoodsComment`, params) };
//评论点赞
export const commentLike= (params) => {params.userId = localStorage.getItem('token'); return axios.post(`/api/commentLike`, params) };
//获取用户收藏列表
export const listUserCollect= (params) => {params.userId = localStorage.getItem('token'); return axios.post(`/api/listUserCollect`, params) };
//删除用户收藏商品
export const deleteUserCollect= (params) => {params.userId = localStorage.getItem('token'); return axios.post(`/api/deleteUserCollect`, params) };
//提交订单
export const submitOrder= (params) => {params.userId = localStorage.getItem('token'); return axios.post(`/api/submitOrder`, params) };
//结算订单（web端）
export const payOrderWeb= (params) => { return axios.post(`/api/payOrderWeb`, params) };
//生成二维码
export const getQRCode= (params) => { return axios.post(`/api/getQRCode`, params) };

//获取跳转分类
export const listSkipClassify= (params) => { return axios.post(`/api/listSkipClassify`, params) };
//获取联系我们
export const listContactUs= (params) => { return axios.post(`/api/listContactUs`, params) };
//获取联系我们详情
export const selectDsContactUsById= (params) => { return axios.post(`/api/selectDsContactUsById`, params) };
//根据分类查询商品
export const listGoodsByFilterField= (params) => { return axios.post(`/api/listGoodsByFilterField`, params) };
//根据分类id查询商品筛选字段
export const listFilterField= (params) => { return axios.post(`/api/listFilterField`, params) };
//加入我们
export const joinUs= (params) => { return axios.post(`/api/joinUs`, params) };
//删除信息
export const deleteMessage= (params) => {params.userId = localStorage.getItem('token'); return axios.post(`/api/deleteMessage`, params) };

//getOrderNumber
export const getOrderNumber= (params) => {return axios.post(`/api/getOrderNumber`, params) };



//获取公司信息
export const getCompanyInfo= (params) => { return axios.post(`/api/getCompanyInfo`, params) };
//验证支付成功
export const getOrderStatus= (params) => { return axios.post(`/api/getOrderStatus`, params) };
// 查看物流
export const listdsnChuru= (params) => { return axios.post(`/api/listdsnChuru`, params) };







