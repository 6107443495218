<template>
  <div id="app">
    <router-view  v-if='isRouterAlive'></router-view>
  </div>
</template>

<script>
var Tawk_API=Tawk_API||{};
// var Tawk_LoadStart=new Date();
(function(){
var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
s1.async=true;
s1.src='https://embed.tawk.to/65f94f3b1ec1082f04d8e2b1/1hpatr0l1';
s1.charset='UTF-8';
s1.setAttribute('crossorigin','*');
s0.parentNode.insertBefore(s1,s0);
})();
import {visitorLogin} from '@/api/main.js'
export default {
  name: 'app',
  // inject: ['reload'],
  data() {
    return {
      isRouterAlive:true
    };
  },
  provide () {
    return {
      reload: this.reload
    }
  },
  watch:{
    '$i18n.locale'(newVal,oldVal){
      if(newVal != oldVal){
        this.reload()//语言切换全局更新
      }
    }
  },
  updated() {
    window.scroll(0, 0);
  },
  mounted() {
    this.visitorLogin();
  },
  methods: {
    visitorLogin(){
      visitorLogin().then(()=>{})
    },
    reload () {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
    // 获取用户信息
    getUserInfo() {
      console.log('获取用户信息=========')
      this.$api.mall.getUserInfo({}).then((res = {}) => {
        // 保存到vuex里面
        this.$store.dispatch('saveUserName', res.username);
      });
    },
    // 获取购物车商品数量
    getCartSum() {
      this.$api.mall.getCartSum({}).then((res = 0) => {
        // 保存到vuex里面
        this.$store.dispatch('saveCartCount', res);
      });
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/scss/index.scss'; // 引入重置样式表（覆盖浏览器用户代理样式）
// @import '@/assets/scss/modal.scss'; // 引入模态框样式表
</style>
