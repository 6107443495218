
// 导入vue和vue-router
import Vue from 'vue'
import Router from 'vue-router'
// 加载路由插件解决同一个页面跳转两次的bug
Vue.use(Router)
const originalPush = Router.prototype.push;
Router.prototype.push = function (location) {
  return originalPush.call(this, location).catch(err => err)
}
// 导出路由
export default new Router({
  // 路由规则
  // 1. 默认是新增的首页
  routes: [
    //   {
    //   path: '/',
    //   name: 'indexs',
    //   component: () => import('../pages/WebIndex.vue'),
    // },
    // 首页
    {
      path: '/',
      name: 'home',
      component: () => import('../pages/Home'),
      redirect: '/index', // 重定向
      // 子路由
      children: [
        {
          path: '/index',
          name: 'index',
          component: () => import('../pages/Index/index.vue'),
          meta: {
            requireAuth: false
          }
        }, {
          path: '/contactUs',
          name: 'contactUs',
          component: () => import('../pages/Index/contactUs.vue'),
          title: '联系我们详情',
          meta: {
            requireAuth: false
          }
        }, {
          path: '/activity',
          name: 'activity',
          component: () => import('../pages/activity/index.vue'),
          meta: {
            requireAuth: false // 是否需要 token显示
          }
        }, {
          path: '/search',
          name: 'search',
          component: () => import('../pages/search/index.vue'),
          meta: {
            requireAuth: false // 是否需要 token显示
          }
        }, {
          path: '/commonProblem',
          name: 'commonProblem',
          title: '常见问题',
          component: () => import('../pages/commonProblem/index.vue'),
          meta: {
            requireAuth: false // 是否需要 token显示
          }
        }, {
          path: '/maintain',
          name: 'maintain',
          title: '维修作业流程',
          component: () => import('../pages/afterSale/maintain.vue'),
          meta: {
            requireAuth: false // 是否需要 token显示
          }
        }, {
          path: '/inform',
          name: 'inform',
          title: '维修收费标准',
          component: () => import('../pages/afterSale/inform.vue'),
          meta: {
            requireAuth: false // 是否需要 token显示
          }
        }, {
          path: '/charge',
          name: 'charge',
          title: '客户维修须知',
          component: () => import('../pages/afterSale/charge.vue'),
          meta: {
            requireAuth: false // 是否需要 token显示
          }
        }, {
          path: '/vipSystem',
          name: 'vipSystem',
          title: '会员制度',
          component: () => import('../pages/commonProblem/vipSystem.vue'),
          meta: {
            requireAuth: false // 是否需要 token显示
          }
        }, {
          path: '/productDetails',
          name: 'productDetails',
          title: '商品详情',
          component: () => import('../pages/product/productDetails.vue'),
          meta: {
            requireAuth: true // 是否需要 token显示
          }
        },
        {
          path: '/productSku',
          name: 'productSku',
          component: () => import('../pages/product/productSku.vue'),
          meta: {
            requireAuth: false // 是否需要 token显示
          }
        }, {
          path: '/submit',
          name: 'submit',
          component: () => import('../pages/submit/submitOrder.vue'),
          meta: {
            requireAuth: false // 是否需要 token显示
          }
        }, {
          path: '/blogs',
          name: 'blogs',
          title: '博客',
          component: () => import('../pages/blogs/index.vue'),
          meta: {
            requireAuth: false // 是否需要 token显示
          }
        }, {
          path: '/details',
          name: 'details',
          title: '博客详情',
          component: () => import('../pages/blogs/details.vue'),
          meta: {
            requireAuth: false // 是否需要 token显示
          }
        }, {
          path: '/login',
          name: 'login',
          title: '登录',
          component: () => import('../pages/login/login.vue'),
          meta: {
            requireAuth: false // 是否需要 token显示
          }
        }, {
          path: '/about',
          name: 'about',
          title: '关于我们',
          component: () => import('../pages/About/index.vue'),
          meta: {
            requireAuth: false // 是否需要 token显示
          },
          children: [{
            path: '/About/introduction',
            name: 'introduction',
            title: '公司简介',
            component: () => import('../pages/About/introduction.vue'),
            meta: {
              requireAuth: false // 是否需要 token显示
            }
          }, {
            path: '/About/brand',
            name: 'brand',
            title: '品牌简介',
            component: () => import('../pages/About/brand.vue'),
            meta: {
              requireAuth: false // 是否需要 token显示
            }
          }, {
            path: '/About/ShoppingInstructions',
            name: 'ShoppingInstructions',
            title: '购物说明',
            component: () => import('../pages/About/ShoppingInstructions.vue'),
            meta: {
              requireAuth: false // 是否需要 token显示
            }
          }, {
            path: '/About/leaveAMessage',
            name: 'leaveAMessage',
            title: '客户留言',
            component: () => import('../pages/About/leaveAMessage.vue'),
            meta: {
              requireAuth: true // 是否需要 token显示
            }
          }, {
            path: '/About/privacy',
            name: 'privacy',
            title: '隐私协议',
            component: () => import('../pages/About/privacy.vue'),
            meta: {
              requireAuth: false // 是否需要 token显示
            }
          }
          ]
        }, {
          path: '/mine',
          name: 'mine',
          component: () => import('../pages/mine/index.vue'),
          meta: {
            requireAuth: false // 是否需要 token显示
          },
          children: [
            {
              path: '/mine/userInfo',
              name: 'userInfo',
              component: () => import('../pages/mine/userInfo.vue'),
              meta: {
                requireAuth: true // 是否需要 token显示
              }
            }, {
              path: '/mine/favorite',
              name: 'favorite',
              component: () => import('../pages/mine/favorite.vue'),
              meta: {
                requireAuth: true // 是否需要 token显示
              }
            }, {
              path: '/mine/shoppingCart',
              name: 'shoppingCart',
              component: () => import('../pages/mine/shoppingCart.vue'),
              meta: {
                requireAuth: true // 是否需要 token显示
              }
            }, {
              path: '/mine/orderList',
              name: 'orderList',
              component: () => import('../pages/mine/orderList.vue'),
              meta: {
                requireAuth: true // 是否需要 token显示
              }
            }, {
              path: '/mine/vipGrade',
              name: 'vipGrade',
              component: () => import('../pages/mine/vipGrade.vue'),
              meta: {
                requireAuth: true // 是否需要 token显示
              }
            }, {
              path: '/mine/message',
              name: 'message',
              component: () => import('../pages/mine/message.vue'),
              meta: {
                requireAuth: true // 是否需要 token显示
              }
            }, {
              path: '/mine/setingPassword',
              name: 'setingPassword',
              component: () => import('../pages/mine/setingPassword.vue'),
              meta: {
                requireAuth: true // 是否需要 token显示
              }
            }, {
              path: '/mine/orderDetails',
              name: 'orderDetails',
              component: () => import('../pages/mine/orderDetails.vue'),
              meta: {
                requireAuth: true // 是否需要 token显示
              }
            }, {
              path: '/mine/orderScore',
              name: 'orderScore',
              component: () => import('../pages/mine/orderScore.vue'),
              meta: {
                requireAuth: true // 是否需要 token显示
              }
            }, {
              path: '/mine/afterSale',
              name: 'afterSale',
              title: '申请售后',
              component: () => import('../pages/mine/afterSale.vue'),
              meta: {
                requireAuth: true // 是否需要 token显示
              }
            }, {
              path: '/mine/lookApply',
              name: 'lookApply',
              title: '查看售后申请',
              component: () => import('../pages/mine/lookApply.vue'),
              meta: {
                requireAuth: true // 是否需要 token显示
              }
            }, {
              path: '/mine/logistics',
              name: 'logistics',
              title: '查看物流',
              component: () => import('../pages/mine/logistics.vue'),
              meta: {
                requireAuth: true // 是否需要 token显示
              }
            }
          ]
        }
      ]
    },
    {
      path: '/404',
      name: '404',
      component: () => import('../pages/404.vue'),
    },
  ]
})